import React from 'react';
import '../css/home.css'; // Import your CSS file

export default function Home() {
  return (
    <div>
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src="images/1.png" alt="First slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/7.png" alt="Second slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/3.png" alt="Third slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/4.png" alt="Fourth slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/5.png" alt="Fifth slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/6.png" alt="Sixth slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="images/2.png" alt="Seventh slide" />
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="bottom-spacing"></div>
    </div>
  );
}
