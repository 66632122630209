import React from 'react';
import "../css/imagedetail.css";
const categories = [

  {
    name: "Puranik City Reserva",
    images: [
      "/images/Project Management advisory/Puranik City Reserva/PCReserva4.jpg",
      "/images/Project Management advisory/Puranik City Reserva/PCReserva7.jpg",
      "/images/Project Management advisory/Puranik City Reserva/PCReserva12.jpg"
      
    ]
  },

  {
    name: "Puraniks Future City Nerul",
    images: [
      "/images/Project Management advisory/Puraniks Future City Nerul/DSC_1393.jpg",
      "/images/Project Management advisory/Puraniks Future City Nerul/DSC_1404.jpg",
      "/images/Project Management advisory/Puraniks Future City Nerul/DSC_1419.jpg",
      "/images/Project Management advisory/Puraniks Future City Nerul/DSC_1429.jpg"
      
    ]
  },

  {
    name: "Puraniks Sayama Clubhouse - Lonavala",
    images: [
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9272.jpg",
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9312.jpg",
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9323.jpg",
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9353.jpg",
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9357.jpg",
      "/images/Project Management advisory/Puraniks Sayama Clubhouse - Lonavala/DSC_9373.jpg",
      
    ]
  },

  {
    name: "Tokyo Bay Sales Office",
    images: [
      "/images/Project Management advisory/Tokyo Bay Sales Office/_DSC1215.jpg",
      "/images/Project Management advisory/Tokyo Bay Sales Office/_DSC1246.jpg",
      "/images/Project Management advisory/Tokyo Bay Sales Office/_DSC1250.jpg",
      "/images/Project Management advisory/Tokyo Bay Sales Office/_DSC1256.jpg"
      
    ]
  },

  {
    name: "Abhitante Pune",
    images: [
      "/images/Project Management advisory/Abhitante Pune/2W6A2127.jpg",
      "/images/Project Management advisory/Abhitante Pune/2W6A2214.jpg",
      "/images/Project Management advisory/Abhitante Pune/2W6A2236.jpg"
    ]
  },
  {
    name: "Home Town Lobby",
    images: [
      "/images/Project Management advisory/Home Town Lobby/_DSC1189.jpg"
    ]
  },
  {
    name: "HT Show Flat",
    images: [
      "/images/Project Management advisory/HT Show Flat/DSC_00560001.jpg"
    ]
  },
  {
    name: "Kavyashara Sample Flat",
    images: [
      "/images/Project Management advisory/Kavyashara Sample Flat/DSC_0004.jpg",
      "/images/Project Management advisory/Kavyashara Sample Flat/DSC_0088.jpg",
    ]
  },
  {
    name: "Mahalunge Show Flat",
    images: [
      "/images/Project Management advisory/Mahalunge Show Flat/100_0026.jpg",
      "/images/Project Management advisory/Mahalunge Show Flat/100_0034.jpg",
      "/images/Project Management advisory/Mahalunge Show Flat/badroom1-b.jpg",
      "/images/Project Management advisory/Mahalunge Show Flat/BEDROOM 2 b.jpg",
      "/images/Project Management advisory/Mahalunge Show Flat/HALL 2 SHOT.jpg"
      
    ]
  },
  {
    name: "Pune Office - Puraniks",
    images: [
      "/images/Project Management advisory/Pune Office - Puraniks/DSC_0795.jpg",      
    ]
  },
  
  {
    name: "Puranik City Sales Office",
    images: [
      "/images/Project Management advisory/Puranik City Sales Office/MAN_5978.jpg",
      "/images/Project Management advisory/Puranik City Sales Office/MAN_5988.jpg",
      "/images/Project Management advisory/Puranik City Sales Office/MAN_6048.jpg",
      
    ]
  },
  {
    name: "Puranik HO",
    images: [
      "/images/Project Management advisory/Puranik HO/MAN_7829.jpg",
      "/images/Project Management advisory/Puranik HO/MAN_7892.jpg"
      
    ]
  },
  {
    name: "Puraniks Abhitante Bavdhan",
    images: [
      "/images/Project Management advisory/Puraniks Abhitante Bavdhan/MAN_8979.jpg",
      "/images/Project Management advisory/Puraniks Abhitante Bavdhan/MAN_9001.jpg",
      "/images/Project Management advisory/Puraniks Abhitante Bavdhan/MAN_9007.jpg"
      
    ]
  },
  {
    name: "Puraniks Aldea Mahalunge Club House And Gym",
    images: [
      "/images/Project Management advisory/Puraniks Aldea Mahalunge Club House And Gym/MAN_9057.jpg",
      "/images/Project Management advisory/Puraniks Aldea Mahalunge Club House And Gym/MAN_9119.jpg",
      "/images/Project Management advisory/Puraniks Aldea Mahalunge Club House And Gym/MAN_9135.jpg",
      "/images/Project Management advisory/Puraniks Aldea Mahalunge Club House And Gym/MAN_9137.jpg"
      
    ]
  },
  {
    name: "Puraniks Call Centre",
    images: [
      "/images/Project Management advisory/Puraniks Call Centre/MAN_0814.jpg",
      "/images/Project Management advisory/Puraniks Call Centre/MAN_0867.jpg"
      
    ]
  },
  
  {
    name: "Puraniks Pune",
    images: [
      "/images/Project Management advisory/Puraniks Pune/DSC_4610.jpg",
      "/images/Project Management advisory/Puraniks Pune/DSC_4622.jpg"
      
    ]
  },
  
  {
    name: "Puraniks Sayama Sales Office",
    images: [
      "/images/Project Management advisory/Puraniks Sayama Sales Office/MAN_9153.jpg",
      "/images/Project Management advisory/Puraniks Sayama Sales Office/MAN_9174.jpg"
      
    ]
  },
  {
    name: "Puraniks Sayama Sample Flat",
    images: [
      "/images/Project Management advisory/Puraniks Sayama Sample Flat/5.jpg",
      "/images/Project Management advisory/Puraniks Sayama Sample Flat/19.jpg"
      
    ]
  },
  {
    name: "RB Show Flat",
    images: [
      "/images/Project Management advisory/RB Show Flat/DSC_4954.jpg",
      "/images/Project Management advisory/RB Show Flat/DSC_5027.jpg"
      
    ]
  },
  {
    name: "Rumah Bali Lobby",
    images: [
      "/images/Project Management advisory/Rumah Bali Lobby/_DSC1310.jpg",
      "/images/Project Management advisory/Rumah Bali Lobby/_DSC1313.jpg"
      
    ]
  },
  {
    name: "Sayama Show Flat",
    images: [
      "/images/Project Management advisory/Sayama Show Flat/_MG_0670.jpg",
      "/images/Project Management advisory/Sayama Show Flat/_MG_0711.jpg",
      "/images/Project Management advisory/Sayama Show Flat/_MG_0717.jpg"
      
    ]
  },
  {
    name: "Tokyo Bay Lobby",
    images: [
      "/images/Project Management advisory/Tokyo Bay Lobby/_DSC1293.jpg",
      "/images/Project Management advisory/Tokyo Bay Lobby/_DSC1301.jpg"
      
    ]
  },
 
  {
    name: "Zeeeneth Show Flat",
    images: [
      "/images/Project Management advisory/Zeeeneth Show Flat/_MG_9331.jpg",
      "/images/Project Management advisory/Zeeeneth Show Flat/_MG_9332.jpg",
      "/images/Project Management advisory/Zeeeneth Show Flat/_MG_9344.jpg"
      
    ]
  },
];

const Projectmanagement = () => {
  return (
    <div className="image-detail">
      {categories.map((category, idx) => (
        <div key={idx} className="category-container">
          <h2>{category.name}</h2>
          <div className="image-grid">
            {category.images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={category.name}
                className="image-detail img"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Projectmanagement;
