import React from 'react';
import '../css/about.css';

export default function About() {
  return (
    <div className="team-page">
      <h2 className="team-heading">MEET THE TEAM</h2>
      <div className="team-container">

        <div className="team-member">
          <div className="team-description">

            <h5>Trupti Puranik</h5>
            <p>Trupti Puranik, born on January 21, 1971, in Thane, is a renowned interior designer known for her exceptional work in residential and commercial spaces. Specializing in modern and intuitive designs, she founded Studios Elements in 2014, quickly establishing it as a leading interior design firm. Trupti's passion for creating beautiful yet functional spaces led her to pioneer modular kitchens, making quality designs accessible to all.With over 10,000 successful projects, her visionary leadership and commitment to excellence continue to inspire.</p>

            <p>  With over 10,000 successful projects, her visionary leadership and commitment to excellence continue to inspire.</p>
          </div>
          <div className="team-img-circle">
            <img src="/images/ceo.666985432a5c7bd72781.JPG" alt="Team Member" />
          </div>
        </div>
      </div>
      <h2 className="team-heading">OUR TALENTED TEAM</h2>
      <div className="team-img-rectangle">
        <img src='../images/team.jpeg' alt="Team Member" />
      </div>
      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
}
