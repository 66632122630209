import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/services.css";

const categories = [
  {
    name: "INTERIOR DESIGN",
    description: "Interior design for Offices, Retail, Education, Hospitality & Residential.",
    images: [
      "/images/_DSC6395.jpg",

    ]
  },
  {
    name: "PROJECT MANAGEMENT & ADVISORY",
    description: "Project management services.",
    images: [
      "/images/_DSC1215.jpg"
    ]
  },
  {
    name: "ARCHITECTURE",
    description: "Architectural design services.",
    images: [
      "/images/WhatsApp Image 2019-06-06 at 23.19.49.jpeg"
    ]
  },
  {
    name: "Interior Styling",
    description: "Interior Styling services.",
    images: [
      "/images/Option (7).jpg"
    ]
  }
];

export default function Services() {
  const navigate = useNavigate();

  const handleImageClick = (categoryName, index) => {
    navigate(`/service/${categoryName}/${index}`);
  };

  return (
    <div style={{ margin: "14px" }}>
      <div className="row">
        <div className="column containerr">
          <div className="iconed-box-content">
            <h5 className='uppercase'> INTERIOR DESIGN</h5>
            <p></p>
            <p style={{ textAlign: "center" }}>
              <span style={{ color: "#999999", lineHeight: "21px" }}>
                Interior design for Offices, Retail, Education, Hospitality & Residential.
              </span>
            </p>
          </div>
        </div>

        <div className="column containerr">
          <img
            src={categories[0].images[0]}
            style={{ maxWidth: '100%', height: 'auto', width: '100%', marginTop: "0" }}
            alt="Interior Design"
            onClick={() => handleImageClick(categories[0].name, 0)}
          />
        </div>

        <div className="column containerr">
          <div className="iconed-box-content">
            <h5 className='uppercase'> PROJECT MANAGEMENT & ADVISORY</h5>
            <p></p>
            <p style={{ textAlign: "center" }}>
              <span style={{ color: "#999999", lineHeight: "21px" }}>
                Project management services.
              </span>
            </p>
          </div>
        </div>

        <div className="column containerr">
          <img
            src={categories[1].images[0]}
            style={{ maxWidth: '100%', height: 'auto', width: '100%', marginTop: "0" }}
            alt="Project Management"
            onClick={() => handleImageClick(categories[1].name, 0)}
          />
        </div>

      </div>


      <div className="row">
        <div style={{ marginTop: "-3.1%" }} className="column containerr">
          <img
            src={categories[2].images[0]}
            style={{ maxWidth: '100%', height: 'auto', width: '100%', marginTop: "0" }}
            alt="Architecture"
            onClick={() => handleImageClick(categories[2].name, 0)}
          />
        </div>
        <div className="column containerr">
          <div className="iconed-box-content">
            <h5 className='uppercase'> ARCHITECTURE</h5>
            <p></p>
            <p style={{ textAlign: "center" }}>
              <span style={{ color: "#999999", lineHeight: "21px" }}>
                Architectural design services.
              </span>
            </p>
          </div>
        </div>
        <div style={{ marginTop: "-3.1%" }} className="column containerr">
          <img
            src={categories[3].images[0]}
            style={{ maxWidth: '100%', height: 'auto', width: '100%', marginTop: "0" }}
            alt="Architecture"
            onClick={() => handleImageClick(categories[3].name, 0)}
          />
        </div>
        <div className="column containerr">
          <div className="iconed-box-content">
            <h5 className='uppercase'> Interior Styling</h5>
            <p></p>
            <p style={{ textAlign: "center" }}>
              <span style={{ color: "#999999", lineHeight: "21px" }}>
                Interior Styling services.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
