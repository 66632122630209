import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from './components/home';
import Header from './components/header';
import Contacts from './components/contact';
import Services from './components/services';
import About from './components/about';
import Interiordesign from './components/interiordesign';
import Projectmanagement from './components/projectmanagement';
import Architecture1 from './components/architecture1';
import Interiorstyling from './components/interiorstyling';

export default function App() {
  return (
    <div>
      <Routes>
        
      <Route path='/header' element={<Header/>}/>
      <Route path='/contact' element={<Contacts/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path="/service/:categoryName/:index" element={<Interiordesign />} />
      <Route path="/service/PROJECT MANAGEMENT & ADVISORY/:index" element={<Projectmanagement />} />
      <Route path="/service/ARCHITECTURE/:index" element={<Architecture1 />} />
      <Route path="/service/Interior Styling/:index" element={<Interiorstyling />} />
    

    <Route path='/' element={<Home/>}/>
    </Routes>
    </div>
  )
}
