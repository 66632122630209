import React from 'react';
import "../css/imagedetail.css";

const categories = [

  {
    name: "PC Reserva Sample Flat",
    description :"* Total area - ",
    description1 :"900 square feet.",
    description2 :"* Features - ",
    description3 :"Blend of comtemporary and Classical theme.",
    images: [
      "/images/Interior design/PC Reserva Sample Flat/DSC_9837.jpg",
      "/images/Interior design/PC Reserva Sample Flat/DSC_9881.jpg"
    ]
  },

  {
    name: "Atarah",
    description :"* Total area - ",
    description1 :"450 square feet.",
    description2 :"* Features - ",
    description3 :"Minimal Furniture, Spacious, placement of classy elements that complements the bright color.",
    images: [
      "/images/Interior design/Atarah/Aatarah5.jpg",
      "/images/Interior design/Atarah/Aatarah6.jpg",
      "/images/Interior design/Atarah/Aatarah9.jpg",
    ]
  },


  {
    name: "Enforme",
    images: [
      "/images/Interior design/Enforme/EnForme1.jpg",
      "/images/Interior design/Enforme/EnForme7.jpg",
    ]
  },

  {
    name: "Astha Group",
    description :"* Total area - ",
    description1 :"1000 square feet.",
    description2 :"* Features - ",
    description3 :"All office interior, Office Reception / Conference room.",
    images: [
      "/images/Interior design/Astha Group/MAN_7586.jpg",
      "/images/Interior design/Astha Group/MAN_7606.jpg",
    ]
  },

  {
    name: "Jagruti Vikam Residence",
    description :"* Total area - ",
    description1 :"1000 square feet.",
    description2 :"* Features - ",
    description3 :"Blend of contemporary and modern theme",
    images: [
      "/images/Interior design/Jagruti Vikam Residence/DSC_4658.jpg",
      "/images/Interior design/Jagruti Vikam Residence/DSC_4691.jpg",
      "/images/Interior design/Jagruti Vikam Residence/DSC_4702.jpg"
    ]
  },
  {
    name: "Kala Mahesh Residence",
    description :"* Total area - ",
    description1 :"1200 square feet.",
    description2 :"* Features - ",
    description3 :"Blend of contemporary and Moder theme",
    images: [
      "/images/Interior design/Kala Mahesh Residence/IMG_8840.jpg",
      "/images/Interior design/Kala Mahesh Residence/IMG_8876.jpg"
    ]
  },

  {
    name: "MCHI",
    description :"* Total area - ",
    description1 :"2000 square feet.",
    description2 :"* Features - ",
    description3 :"All office interior, Office Reception / conference room",
    images: [
      "/images/Interior design/MCHI/DSC_1057.jpg",
      "/images/Interior design/MCHI/DSC_1074.jpg"
    ]
  },

  {
    name: "Reshma Karite  Residence",
    description :"* Total area - ",
    description1 :"650 square feet.",
    description2 :"* Features - ",
    description3 :"Modern Contemporary theme with natural elements which complements the theme beautifully.",
    images: [
      "/images/Interior design/Reshma Karite  Residence/_DSC4868.jpg",
      "/images/Interior design/Reshma Karite  Residence/_DSC4901.jpg"
    ]
  },

  {
    name: "Nakhwa Residence",
    description :"* Total area - ",
    description1 :"1800 square feet.",
    description2 :"* Features - ",
    description3 :"Blend of Contemporary and Traditional theme.",
    images: [
      "/images/Interior design/Nakhwa Residence/DSC_0005.jpg",
      "/images/Interior design/Nakhwa Residence/DSC_0043.jpg"
    ]
  },
  
  {
    name: "Nilesh Puranik Residence",
    description :"* Total area - ",
    description1 :"1500 square feet.",
    description2 :"* Features - ",
    description3 :"Traditional look complemented with exclusive placement of lighting to make it classy and distinct.",
    images: [
      "/images/Interior design/Nilesh Puranik Residence/_DSC5438.jpg",
      "/images/Interior design/Nilesh Puranik Residence/_DSC5501.jpg"
    ]
  },

  {
    name: "Amogh Kulkarni Residence",
    images: [
      "/images/Interior design/Amogh Kulkarni Residence/MAN_0562.jpg",
      "/images/Interior design/Amogh Kulkarni Residence/MAN_0587.jpg",

    ]
  },
  {
    name: "Amul Parlour",
    images: [
      "/images/Interior design/Amul Parlour/DSC_1682.jpg",
    ]
  },
  {
    name: "Anuja Parlour",
    images: [
      "/images/Interior design/Anuja Parlour/DSC01776.jpg",
      "/images/Interior design/Anuja Parlour/DSC01778.jpg",
    ]
  },
  {
    name: "Anuja Tipnis Residence",
    images: [
      "/images/Interior design/Anuja Tipnis Residence/_DSC8638.jpg",
      "/images/Interior design/Anuja Tipnis Residence/_DSC8666.jpg",
    ]
  },
  
  
  {
    name: "Bendre Dosti 25-02-201 Residence",
    images: [
      "/images/Interior design/Bendre Dosti 25-02-201 Residence/DSC_3703.jpg",
      "/images/Interior design/Bendre Dosti 25-02-201 Residence/DSC_3715.jpg",
    ]
  },
  {
    name: "Dr. Kambale Residence",
    images: [
      "/images/Interior design/Dr. Kambale Residence/6R9A7207-1.jpg",
      "/images/Interior design/Dr. Kambale Residence/6R9A7327-1.jpg",
    ]
  },
  
  {
    name: "Falguni  Residence",
    images: [
      "/images/Interior design/Falguni  Residence/DSC03225.jpg",
      "/images/Interior design/Falguni  Residence/DSC03291.jpg",
    ]
  },
  {
    name: "Falguni 3 BHK 2022  Residence",
    images: [
      "/images/Interior design/Falguni 3 BHK 2022  Residence/15.jpg",
      "/images/Interior design/Falguni 3 BHK 2022  Residence/17.jpg",
    ]
  },
  {
    name: "Fort CA Office",
    images: [
      "/images/Interior design/Fort CA Office/DSC_2398.jpg",
      "/images/Interior design/Fort CA Office/DSC_2406.jpg"
    ]
  },
  {
    name: "Gauri deshpande  Residence",
    images: [
      "/images/Interior design/Gauri deshpande  Residence/Edited.jpg",
      "/images/Interior design/Gauri deshpande  Residence/image00049.jpg"
    ]
  },
  {
    name: "Gupta Residence",
    images: [
      "/images/Interior design/Gupta Residence/_DSC5360.jpg",
      "/images/Interior design/Gupta Residence/_DSC5405.jpg"
    ]
  },
  
  {
    name: "Kishor Patil Residence",
    images: [
      "/images/Interior design/Kishor Patil Residence/DSC_1181.jpg",
      "/images/Interior design/Kishor Patil Residence/DSC_1197.jpg",
      "/images/Interior design/Kishor Patil Residence/DSC_1242.jpg"
    ]
  },
  {
    name: "Kulkarni Residence",
    images: [
      "/images/Interior design/Kulkarni Residence/IMG_5399.jpg",
      "/images/Interior design/Kulkarni Residence/IMG_5410.jpg"
    ]
  },
  {
    name: "Manish Surekha Residence",
    images: [
      "/images/Interior design/Manish Surekha Residence/MAN_0591.jpg",
      "/images/Interior design/Manish Surekha Residence/MAN_0670.jpg"
    ]
  },
  {
    name: "Manjrekar Residence",
    images: [
      "/images/Interior design/Manjrekar Residence/MAN_7422.jpg",
      "/images/Interior design/Manjrekar Residence/MAN_7424.jpg",
      "/images/Interior design/Manjrekar Residence/MAN_7442.jpg"
    ]
  },
  
  {
    name: "Medeows Cafe",
    images: [
      "/images/Interior design/Medeows Cafe/DSC_1691.jpg"
    ]
  },
  {
    name: "Mhatre Residence",
    images: [
      "/images/Interior design/Mhatre Residence/IMG_0022.jpg",
      "/images/Interior design/Mhatre Residence/IMG_0033 (72).jpg"
    ]
  }, 

  {
    name: "Neha Kulkarni Residence",
    images: [
      "/images/Interior design/Neha Kulkarni Residence/MAN_7460.jpg",
      "/images/Interior design/Neha Kulkarni Residence/MAN_7577.jpg",
      "/images/Interior design/Neha Kulkarni Residence/MAN_7493.jpg"
    ]
  },  {
    name: "Patade Residence",
    images: [
      "/images/Interior design/Patade Residence/Patade3.jpg",
      "/images/Interior design/Patade Residence/Patade7.jpg",
      "/images/Interior design/Patade Residence/Patade8.jpg"
    ]
  },  {
    name: "Prashant Joshi  Residence",
    images: [
      "/images/Interior design/Prashant Joshi  Residence/DSC_2287.jpg",
      "/images/Interior design/Prashant Joshi  Residence/DSC_2450.jpg",
      "/images/Interior design/Prashant Joshi  Residence/DSC_2469.jpg",
      "/images/Interior design/Prashant Joshi  Residence/DSC_2478.jpg"
    ]
  }, {
    name: "Puraniks Sayama (Lonavala) Sample Flat",
    images: [
      "/images/Interior design/Puraniks Sayama (Lonavala) Sample Flat/MAN_9286.jpg",
      "/images/Interior design/Puraniks Sayama (Lonavala) Sample Flat/MAN_9310.jpg"
    ]
  }, {
    name: "Rajendra Surve Residence",
    images: [
      "/images/Interior design/Rajendra Surve Residence/MAN_1794.jpg",
      "/images/Interior design/Rajendra Surve Residence/MAN_1815.jpg"
    ]
  }, {
    name: "Rajput Residence",
    images: [
      "/images/Interior design/Rajput Residence/MAN_6101.jpg",
      "/images/Interior design/Rajput Residence/MAN_6126.jpg"
    ]
  },
  {
    name: "Randhir Rathod Residence",
    images: [
      "/images/Interior design/Randhir Rathod Residence/DSC01424.jpg",
      "/images/Interior design/Randhir Rathod Residence/DSC01448.jpg"
    ]
  },
  {
    name: "Randhire  Residence",
    images: [
      "/images/Interior design/Randhire  Residence/_DSC1129.jpg",
      "/images/Interior design/Randhire  Residence/_DSC1142.jpg",
      "/images/Interior design/Randhire  Residence/_DSC1147.jpg"
    ]
  },
  
  {
    name: "Sakshi Agarwal Residence",
    images: [
      "/images/Interior design/Sakshi Agarwal Residence/MAN_6079.jpg"
    ]
  },
  {
    name: "Sandesh Bhoir Residence",
    images: [
      "/images/Interior design/Sandesh Bhoir Residence/DSC_1098.jpg",
      "/images/Interior design/Sandesh Bhoir Residence/DSC_1141.jpg",
      "/images/Interior design/Sandesh Bhoir Residence/DSC_1151.jpg"
    ]
  },
  {
    name: "Sheetal Chincholkar Residence",
    images: [
      "/images/Interior design/Sheetal Chincholkar Residence/IMG_5454(1).jpg",
      "/images/Interior design/Sheetal Chincholkar Residence/IMG_5464(1).jpg",
      "/images/Interior design/Sheetal Chincholkar Residence/IMG_5565(1).jpg"
    ]
  },
  {
    name: "Shreeji",
    images: [
      "/images/Interior design/Shreeji/DSC_1293.jpg",
      "/images/Interior design/Shreeji/DSC_1307.jpg"
    ]
  },
  {
    name: "Suyash Clinic",
    images: [
      "/images/Interior design/Suyash Clinic/DSC_4590.jpg",
      "/images/Interior design/Suyash Clinic/DSC_4603.jpg"
    ]
  },
  {
    name: "Terapanth",
    images: [
      "/images/Interior design/Terapanth/DSC_0313.jpg",
      "/images/Interior design/Terapanth/DSC_0317.jpg",
      "/images/Interior design/Terapanth/DSC_0333.jpg"
    ]
  },
  {
    name: "Vivek Singh Residence",
    images: [
      "/images/Interior design/Vivek Singh Residence/DSC_3852.jpg",
      "/images/Interior design/Vivek Singh Residence/image00001.jpg",
      "/images/Interior design/Vivek Singh Residence/image00004.jpg",
      "/images/Interior design/Vivek Singh Residence/image00015.jpg"
    ]
  },
  {
    name: "Vrushali Shenoy Residence",
    images: [
      "/images/Interior design/Vrushali Shenoy Residence/WhatsApp Image 2021-02-15 at 2.16.17 PM (3).jpg",
      "/images/Interior design/Vrushali Shenoy Residence/WhatsApp Image 2021-02-15 at 2.16.17 PM (12).jpg"
    ]
  },
  {
    name: "Wadhwani Residence",
    images: [
      "/images/Interior design/Wadhwani Residence/DSC_9007.jpg",
      "/images/Interior design/Wadhwani Residence/DSC_9023.jpg"
    ]
  }
];

const Interiordesign = () => {


  return (
    <div className="image-detail">
      {categories.map((category, idx) => (
        <div key={idx} className="category-container">
          <h1>{category.name}</h1>
          <h4>{category.description}</h4>
          <h5>{category.description1}</h5>
          <h4>{category.description2}</h4>
          <h3>{category.description3}</h3>
          <div className="image-grid">
            {category.images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={category.name}
                className="image-detail img"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Interiordesign;
