import React from 'react';
import "../css/imagedetail.css";

const categories = [
  {
    name: "Accesorries Styling - Falguni residence",
    images: [
      "/images/Interior Styling/Accesorries Styling - Falguni residence/DSC03215.jpg",
      "/images/Interior Styling/Accesorries Styling - Falguni residence/DSC03249.jpg"
    ]
  },
  {
    name: "Contemporary styling - Sayama club house",
    images: [
      "/images/Interior Styling/Contemporary styling - Sayama club house/Contemporary styling.jpg",
    ]
  },
  {
    name: "Curtain Styling - Atarah",
    images: [
      "/images/Interior Styling/Curtain Styling - Atarah/Aatarah5.JPG"
    ]
  },
  {
    name: "Curtain Styling - One Avighna",
    images: [
      "/images/Interior Styling/Curtain Styling - One Avighna/DSC6397.jpg",
      "/images/Interior Styling/Curtain Styling - One Avighna/DSC6414.jpg",
      "/images/Interior Styling/Curtain Styling - One Avighna/DSC6418.jpg",
      "/images/Interior Styling/Curtain Styling - One Avighna/DSC6465.jpg"
    ]
  },
  {
    name: "Green decor Styling - Enforme",
    images: [
      "/images/Interior Styling/Green decor Styling - Enforme/EnForme2.JPG"
    ]
  },
  {
    name: "Sayama Club Reception",
    images: [
      "/images/Interior Styling/Sayama Club Reception/DSC_9272.jpg"
    ]
  },
  {
    name: "Theme Styling - One Avighna",
    images: [
      "/images/Interior Styling/Theme Styling - One Avighna/_DSC6368.jpg",
    ]
  },
  
];

const Interiorstyling = () => {


  return (
    <div className="image-detail">
      {categories.map((category, idx) => (
        <div key={idx} className="category-container">
          <h2>{category.name}</h2>
          <div className="image-grid">
            {category.images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={category.name}
                className="image-detail img"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Interiorstyling;
