import React from 'react';
import "../css/imagedetail.css";

const categories = [
  {
    name: "Abhitante Club House ",
    description: "Total area - 5000 square feet.",
    features: "Features - Gym 1000 square feet, EPDM Flooring. Game zone - Wall paneling, 3D look swimming pool.",
    images: [
      "/images/Architectural/Abhitante Club House/2W6A2141.jpg",
    ]
  },
  {
    name: "Future City Neral",
    images: [
      "/images/Architectural/Future City Neral/DSC_1368.jpg",
      "/images/Architectural/Future City Neral/DSC_1369.jpg"
    ]
  },
  {
    name: "Sales Office - Abhitante Pune",
    images: [
      "/images/Architectural/Sales Office - Abhitante Pune/MAN_8971.jpg",
    ]
  },
  {
    name: "Sales Office - Sayama",
    images: [
      "/images/Architectural/Sales Office - Sayama/MAN_9198.jpg",
      "/images/Architectural/Sales Office - Sayama/MAN_9380.jpg"
    ]
  },
];

const Architecture1 = () => {
  return (
    <div className="image-detail">
      {categories.map((category, idx) => (
        <div key={idx} className="category-container">
          <h1>{category.name}</h1>
          {category.description && <h4>{category.description}</h4>}
          {category.features && <h4>{category.features}</h4>}
          <div className="image-grid">
            {category.images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`${category.name} ${index + 1}`}
                className="image-detail img"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Architecture1;
