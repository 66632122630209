import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faWeight } from "@fortawesome/free-solid-svg-icons";
import { supabase } from "./supabase"; // Import Supabase client

import "react-toastify/dist/ReactToastify.css";
import "../css/contact.css";

export default function Contacts() {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !subject || !message) {
      toast.error("Please provide value in each input field");
    } else {
      try {
        const { data, error } = await supabase
          .from('contacts')
          .insert([{ name, email, subject, message }]);
  
        if (error) {
          console.error("Supabase error details:", error.details);
          console.error("Supabase error hint:", error.hint);
          console.error("Supabase error message:", error.message);
          throw error;
        }
  
        setState({ name: "", email: "", subject: "", message: "" });
        toast.success("Form Submitted Successfully");
      } catch (error) {
        toast.error("Error submitting form");
        console.error("Error adding document: ", error);
      }
    }
  };
  

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div>
      <section className="contact-section">
        <div className="">
          <ToastContainer position="top-center" />
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="wrapper">
                <div style={{ display: "flex", flexDirection: "row-reverse" }} className="row no-gutters">
                  <div className="col-md-6">
                    <div className="w-100 p-lg-5 p-4">
                      <h3 className="mb-4">Send us a message</h3>
                      <form id="contactForm" className="contactForm" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Name"
                                onChange={handleInputChange}
                                value={name}
                                style={{ color: 'black', backgroundColor: 'white', border: "1px solid black", padding: "1%" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                onChange={handleInputChange}
                                value={email}
                                style={{ color: 'black', backgroundColor: 'white', border: "1px solid black", padding: "1%" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                placeholder="Subject"
                                onChange={handleInputChange}
                                value={subject}
                                style={{ color: 'black', backgroundColor: 'white', border: "1px solid black", padding: "1%" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control"
                                name="message"
                                placeholder="Message"
                                cols="30"
                                rows="6"
                                onChange={handleInputChange}
                                value={message}
                                style={{ color: 'black', backgroundColor: 'white', border: "1px solid black", padding: "1%" }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                value="Send Message"
                                className="btn btn-primary"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-stretch">
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }} className="info-wrap w-100 p-lg-5 p-4 img">
                      <div className="w-100 d-flex align-items-start">
                        <div style={{ marginBottom: "2%" }} className="text pl-3">
                          <div>
                            <p style={{ fontWeight: "bold", fontSize: "3wv" }}>Thane- Head Office</p>
                            <div>
                              <p><span style={{ color: "black", fontWeight: "bold" }}>Address:</span> 11, Puraniks Capitol, Opp. Hyper city mall,</p>
                              <p>Kasarvadvali, Ghodbunder road, Thane W 400615</p>
                            </div>
                          </div>
                          <p>
                            <span style={{ color: "black", fontWeight: "bold" }}>Phone:</span>
                            <a style={{ color: "black" }} href="tel://8291996675">+91 8291996675</a>
                          </p>
                          <p>
                            <span style={{ color: "black", fontWeight: "bold" }}>Email:</span>
                            <a style={{ color: "black" }} href="mailto:info@studioelements.in">info@studioelements.in</a>
                          </p>
                        </div>
                      </div>
                      <div className="w-100 d-flex align-items-start">
                        <div style={{ marginBottom: "2%" }} className="text pl-3">
                          <div>
                            <p style={{ fontWeight: "bold" }}>Hyderabad- Branch Office</p>
                            <div>
                              <p><span style={{ color: "black", fontWeight: "bold" }}>Address:</span> Office No. 217, floor- Level 2, Oval Building,</p>
                              <p>Inorbit Mall Road, Telangana - 500081.</p>
                            </div>
                          </div>
                          <p>
                            <span style={{ color: "black", fontWeight: "bold" }}>Phone:</span>
                            <a style={{ color: "black" }} href="tel://9967298631">+91 9967298631</a>
                          </p>
                          <p>
                            <span style={{ color: "black", fontWeight: "bold" }}>Email:</span>
                            <a style={{ color: "black" }} href="mailto:info@studioelements.in">info@studioelements.in</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map-section text-center">
        <div className="container">
          <h2 className="mb-4">Find us on Google Maps</h2>
          <div className="row">
            <div className="col-md-6">
              <h3>Thane</h3>
              <iframe
                title="SE Thane"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.300808146635!2d72.96346227580955!3d19.269280645875163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bba3aecb71eb%3A0x397344c210542ec0!2sInterior%20Design%20Services%20-%20Studio%20Elements!5e0!3m2!1sen!2sin!4v1717967400979!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div className="col-md-6">
              <h3>Hyderabad</h3>
              <iframe
                title="SE Hyderabad"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.586693417229!2d78.37919561542501!3d17.432477887047646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915717ccbea5%3A0xdc04b2d31f9e1cd9!2sLevel%202%2C%20iLabs%20Centre%2C%20Oval%20Building%2C%20Plot%20no.18%2C%20Inorbit%20Mall%20Rd%2C%20Silpa%20Gram%20Craft%20Village%2C%20Madhapur%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1625721090583!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
}
