import React from 'react';
import '../css/header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Header() {
  return (
    <div style={{ marginTop: '20px' }}>
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container-fluid">
          <Link to="#" className="navbar-brand">
            <a href="#">
              <img style={{ width: '200px' }} src="/images/logo_1.png" alt="CoolBrand" />
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="navbar-nav">
              <Link to="/" className="nav-item nav-link active nav-font">Home</Link>
              <Link to="/about" className="nav-item nav-link active nav-font">About</Link>
              <Link style={{ fontWeight: '600' }} to="/services" className="nav-item nav-link active nav-font">Services</Link>
              <Link to="/contact" className="nav-item nav-link active nav-font">Contact Us</Link>
            </div>
            <div className='socialtop'>
              <div className="top-social">
                <a href="https://www.facebook.com/StudioElements.Thane?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://www.instagram.com/studioelements_sepl/?igsh=YXpkcnhiOXBsYTY1" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
